import { Stack, Typography, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

const BackButton = ({ trackingDetails, isLoading = false }) => {
  const navigate = useNavigate();
  const tablet = useMediaQuery("(max-width:900px)");
  console.log("tablet", tablet);
  const goBack = () => {
    if (document?.referrer?.includes(window?.location?.origin)) {
      navigate(-1); // Client-side navigation
    } else {
      window?.history?.back(); // External navigation
    }
  };

  console.log("trackingDetails", trackingDetails);
  console.log("isLoading", isLoading);

  return (
    <Stack direction={"row"} gap={1} alignItems={"center"}>
      <IconButton
        onClick={goBack}
        color="primary"
        sx={{
          borderRadius: "50%",
          backgroundColor: "#fff",
          cursor: "pointer",
          padding: tablet ? "4px" : "8px",
          "&:hover": {
            backgroundColor: "#fff",
          },
          border: "1px solid #fff",
        }}
      >
        <ArrowBack sx={{ color: "rgba(24,17,47,1)" }} fontSize="small" />
      </IconButton>
      <Typography color={"white"} fontSize={{ md: "1.375rem", xs: "0.802rem" }}>
        Back
      </Typography>
    </Stack>
  );
};

BackButton.propTypes = {
  trackingDetails: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default BackButton;
