import PropTypes from "prop-types";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "./LottieJson.json";
import "./LoadingOverlay.scss";

const LoadingOverlay = ({ title }) => {
  return (
    <div className="loading-overlay">
      <Player src={animationData} className="player" loop autoplay />
      <p className="loading-text">{title}</p>
    </div>
  );
};

LoadingOverlay.propTypes = {
  title: PropTypes.string.isRequired,
};

export default LoadingOverlay;
