import React from "react";
import { Box, Typography, Stack, Container } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TRACKING_MANAGE_MY_SHARED_COST_OPTIONS } from "../../../constants";
import BackButton from "../back-button";

const NewSharedHeader = ({
  subheading,
  heading,
  organisation,
  styleClass,
  displayLogo = true,
}) => {
  return (
    <>
      <Box className={`NewSharedHeader ${styleClass}`}>
        <Container className="design-header" maxWidth={false}>
          <BackButton
            trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
          />
          <Stack
            direction={{ sm: "row", xs: "column" }}
            justifyContent={{ sm: "space-between", xs: "" }}
          >
            <Stack direction="column" spacing={1} justifyContent="center">
              <Typography className="NewSharedHeader-sub-heading">
                {subheading}
              </Typography>
              <Stack direction="row" spacing={2}>
                <Typography className="NewSharedHeader-heading">
                  {heading}
                </Typography>
              </Stack>
            </Stack>
            {organisation && displayLogo && (
              <Box className="organisation-logo-container mt-10">
                <Box
                  component="img"
                  alt={organisation.name}
                  className="organisation-logo"
                  src={organisation.logo}
                />
              </Box>
            )}
          </Stack>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state.organisation.organisation,
  };
};

NewSharedHeader.propTypes = {
  subheading: PropTypes.string,
  heading: PropTypes.string,
  styleClass: PropTypes.string,
  organisation: PropTypes.object,
  displayLogo: PropTypes.bool,
};

export default connect(mapStateToProps)(NewSharedHeader);
