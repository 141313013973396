import PropTypes from "prop-types";
import { createContext, useContext, useState, useMemo } from "react";
const PlanContext = createContext({});

export const PlanProvider = ({ children }) => {
  const [plans, setPlans] = useState([]);
  const [isOptOutPendingPlan, setIsOptOutPendingPlan] = useState(null);
  const [isStandardAVCPlan, setIsStandardAVCPlan] = useState(null);
  const [isLatestActivePlan, setLatestActivePlan] = useState(null);
  const [forcefullyFetchPlans, setForcefullyFetchPlans] = useState(false);

  const memoizedValue = useMemo(
    () => ({
      plans,
      isStandardAVCPlan,
      isOptOutPendingPlan,
      isLatestActivePlan,
      setPlans,
      setIsOptOutPendingPlan,
      setIsStandardAVCPlan,
      setLatestActivePlan,
      setForcefullyFetchPlans,
      forcefullyFetchPlans,
    }),
    [plans, isLatestActivePlan, isStandardAVCPlan, isOptOutPendingPlan]
  );

  return (
    <PlanContext.Provider value={memoizedValue}>
      {children}
    </PlanContext.Provider>
  );
};

PlanProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function usePlan() {
  return useContext(PlanContext);
}
