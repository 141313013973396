import { Box, Stack, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import { redirectToURL } from "../../../../../helpers";

const NewTermsAndFaqs = ({ faq, termsAndConditions }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Stack
      paddingX={2.75}
      className="desktop-padding"
      direction={{ xs: "column", md: "row" }}
      gap={isMobile ? 1 : 3}
    >
      <Box
        className="faq-button"
        onClick={() => {
          redirectToURL(faq, true);
        }}
      >
        Frequently Asked Questions
      </Box>
      <Box
        className="term-button"
        onClick={() => {
          redirectToURL(termsAndConditions, true);
        }}
      >
        Terms and Conditions
      </Box>
    </Stack>
  );
};

NewTermsAndFaqs.propTypes = {
  faq: PropTypes.string,
  termsAndConditions: PropTypes.string,
};

export default NewTermsAndFaqs;
