import { gql } from "@apollo/client";

export const fetchAddressSuggestions = gql`
  mutation FetchAddressSuggestions($Term: String!) {
    FetchAddressSuggestions(Term: $Term) {
      message
      data
    }
  }
`;

export const fetchAddressSuggestionsById = gql`
  mutation FetchAddressSuggestionById($id: String!) {
    FetchAddressSuggestionById(id: $id) {
      message
      data
    }
  }
`;
