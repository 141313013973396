import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import LoadingButton from "../../../../generic-components/button";
import contributeIcon from "../../../../../assets/images/official-icon.png";
import {
  dialogName,
  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS,
} from "../../../../../constants";
import ContributionCalculatorModal from "../../shared-cost-application/modal/ContributionCalculatorModal";
import useDialog from "../../../../../hooks/useModal";

const NewPlanGuidance = ({ planDetails }) => {
  const { dialogs, openDialog, closeDialog } = useDialog();
  const [modalInfo, setModalInfo] = useState({});

  const handleCalculatorModal = () => {
    const planStatus = planDetails?.plan_status?.name;
    if (planStatus === "Accepted") {
      setModalInfo({
        applyButtonTitle: "Amend",
        showCloseButton: false,
      });
    } else if (
      [
        "Awaiting Authorisation",
        "Opt Out Pending",
        "Awaiting Provider Match",
        "On Hold – Existing AVC",
        "On Hold - NMW issue",
        "On Hold – ID issue",
        "On Hold – Low Earnings Limit",
        "On Hold - Provider Match Issue",
        "On Hold - Awaiting Employer Action",
        "On Hold – Awaiting Employee Acceptance",
      ].includes(planStatus)
    ) {
      setModalInfo({
        closeButtonTitle: "Close",
        showApplyButton: false,
      });
    } else if (["Cancelled", "Opted-out"].includes(planStatus)) {
      setModalInfo({
        applyButtonTitle: "Apply",
        showCloseButton: false,
      });
    }
  };

  useEffect(() => {
    handleCalculatorModal();
  }, [planDetails]);

  return (
    <>
      <Box>
        <Grid container alignItems={"center"}>
          <Grid tems xs={12} md={4}>
            <Box className="plan-guidance-image">
              <img
                width={180}
                src={contributeIcon}
                alt="image"
                className="header-logo contribute-image"
              />
            </Box>
          </Grid>
          <Grid items xs={12} md={8}>
            <Typography className="plan-guidance-heading">
              How much more could you contribute?
            </Typography>
            <Typography className="plan-guidance-content mt-18">
              Amending your plan and increasing your contributions could make a
              difference to your final pot at retirement.
            </Typography>
            <LoadingButton
              buttonTitle={"Try the Calculator"}
              trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
              handleClick={() => openDialog(dialogName.PLAN_PAGE_CAL)}
              styleClass="calc-btn calculator_btn mt-18"
            />
          </Grid>
        </Grid>

        <ContributionCalculatorModal
          open={dialogs?.includes(dialogName.PLAN_PAGE_CAL)}
          handleClose={() => closeDialog(dialogName.PLAN_PAGE_CAL)}
          applyButtonTitle={modalInfo?.applyButtonTitle}
          closeButtonTitle={modalInfo?.closeButtonTitle}
          showApplyButton={modalInfo?.showApplyButton}
          showCloseButton={modalInfo?.showCloseButton}
          islargeApplyButton={modalInfo?.islargeApplyButton}
        />
      </Box>
    </>
  );
};

NewPlanGuidance.propTypes = {
  planDetails: PropTypes.object,
};

export default NewPlanGuidance;
