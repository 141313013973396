import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CANCELLATION_REASONS,
  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS,
} from "../../../../../../constants";
import { cancelMyPlan } from "../../../../../../graphql/mutations/manageMySharedCost";
import useStepper from "../../../../../../hooks/useStepper";
import usePlan from "../../../../../../hooks/usePlan";
import { planCancellationValidationSchema } from "../../../../../../schema";
import LoadingButton from "../../../../../generic-components/button";
import FormSelect from "../../../../../generic-components/form-select";
import TextInput from "../../../../../generic-components/input-text";
import Loader from "../../../../../generic-components/loader";
import { otherOrganisationOptions } from "../../../../../../constants/reasons";
import { useDispatch } from "react-redux";
import { setUser } from "../../../../../../reducers/userReducer";
import { getPlanCancellationReasons } from "../../../../../../graphql/queries/manageMySharedCost";
import { useEffect } from "react";
import useDialog from "../../../../../../hooks/useModal";
import reasonInfo from "../../../../../../assets/images/reasons-info.png";
import warningIcon from "../../../../../../assets/images/warning.png";
import LoadingOverlay from "../../../../../generic-components/lotte-loader";

const INITIAL_CONSTANT_VALUES = {
  otherReason: "",
  cancellationReason: "",
  isCancellationReasons: true,
  isMovingToAnotherOrganisation: "",
  otherOrganisationName: "",
};

const NewCancellationReasons = ({ plan, setStep, step, handleClose }) => {
  const mobile = useMediaQuery("(max-width:600px)");
  // const tablet = useMediaQuery("(max-width:900px)");

  const {
    setErrorToast,
    setSaveError,
    setPersonalDetails,
    personalDetails,
    setSuccessToast,
  } = useStepper();
  const { setLatestActivePlan, setPlans } = usePlan();
  const { dialogs } = useDialog();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cancellationReasons, setCancellationReasons] = useState([]);

  const [cancelMySharedCostAVCPlan, { loading: cancelMyPlanLoading }] =
    useMutation(cancelMyPlan);
  const [fetchCancellationReasons, { loading: reasonsLoading }] = useLazyQuery(
    getPlanCancellationReasons
  );
  console.log("mobile", mobile, dialogs);

  const handleCancellationReasons = () => {
    fetchCancellationReasons({
      variables: {
        plan_id: parseInt(plan.id),
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const reasons =
          data?.cancellation_reasons_options?.cancellation_reasons;
        const formatReasons = reasons.map((reason) => ({
          id: reason.id,
          name: reason.description,
          value: reason.title,
        }));
        setCancellationReasons(formatReasons);
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const handleCancelPlan = (values) => {
    const otherReason =
      values.cancellationReason === "leaving_or_left_employment" &&
      values.isMovingToAnotherOrganisation === "yes"
        ? values.otherOrganisationName
        : values.otherReason;
    const cancellationReasonId = cancellationReasons.find(
      (reason) => reason.value === values.cancellationReason
    )?.id;

    cancelMySharedCostAVCPlan({
      variables: {
        plan_id: parseInt(plan.id),
        cancellation_reason_id: parseInt(cancellationReasonId),
        cancellation_reason_other: otherReason || "",
      },
      onCompleted: async () => {
        setPlans([]);
        setLatestActivePlan(null);
        setPersonalDetails({
          ...personalDetails,
          latest_active_plan: null,
          latest_temp_plan: null,
        });
        dispatch(setUser({ latest_active_plan: null, latest_temp_plan: null }));
        setSuccessToast("You have successfully cancelled your plan");
        handleClose(true);
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const handleMovingToOtherOrganisation = (_, value, setFieldValue) => {
    setFieldValue("isMovingToAnotherOrganisation", value);
  };

  const handleOtherReason = (_, value, setFieldValue) => {
    // openDialog(value);
    if (value !== "other_reason") {
      setFieldValue("otherReason", "");
      return;
    }
  };

  useEffect(() => {
    if (plan.id) {
      handleCancellationReasons();
    }
  }, [plan]);

  return (
    <>
      {reasonsLoading ? (
        <Box className="flex-box-center min-height-20 full-width mt-100">
          <Loader />
        </Box>
      ) : (
        <Box className="new-cancel-plan-content">
          {step === 1 && (
            <Box width={"82%"} textAlign={"center"} marginX={"auto"}>
              <img src={reasonInfo} alt="info" />
              <Typography className="mt-18 new-padding-content-heading">
                Please let us know why you're cancelling
              </Typography>
              <Typography className="mt-18 mb-18 padding-content">
                We're sorry to see you go, but we hope to assist you again in
                the future. You’re welcome to reapply and join the scheme
                anytime.
              </Typography>
            </Box>
          )}
          <Box className="padding-content full-width">
            <Formik
              initialValues={INITIAL_CONSTANT_VALUES}
              validationSchema={planCancellationValidationSchema}
              onSubmit={(values) => {
                handleCancelPlan(values);
              }}
            >
              {({ values, handleSubmit, isValid, resetForm }) => {
                useEffect(() => {
                  if (step === 1) {
                    resetForm();
                  }
                }, [step, resetForm]);
                return (
                  <Form onSubmit={handleSubmit}>
                    {step === 1 ? (
                      <Box width={"82%"} marginX={"auto"} className="mt-18">
                        <Typography className="drop-down-label">
                          Cancellation Reasons
                        </Typography>
                        <FormSelect
                          name="cancellationReason"
                          labelId="cancellationReason"
                          label="Selection from dropdown"
                          styleClass="new-select-form"
                          width={100}
                          menuItems={cancellationReasons}
                          trackingDetails={
                            TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                          }
                          handleCustomChange={(_, value, setFieldValue) => {
                            handleOtherReason(null, value, setFieldValue);
                          }}
                        />
                        <Stack
                          justifyContent="center"
                          direction="row"
                          className="mt-30"
                        >
                          <LoadingButton
                            buttonTitle="Next"
                            trackingDetails={
                              TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                            }
                            handleClick={() => setStep(2)}
                            styleClass="cancellation-next-btn"
                            disabled={!values.cancellationReason}
                            loading={cancelMyPlanLoading}
                          />
                        </Stack>
                      </Box>
                    ) : (
                      <>
                        {(values.cancellationReason ===
                          CANCELLATION_REASONS.retirement ||
                          values.cancellationReason ===
                            CANCELLATION_REASONS.flexible_retirement ||
                          values.cancellationReason ===
                            CANCELLATION_REASONS.absent_from_work_with_insufficient_pay) && (
                          <Box textAlign={"center"}>
                            <img src={warningIcon} alt="warning" />
                            <Typography className="mt-18 new-padding-content-heading">
                              Absent from work with insufficient pay (maternity,
                              sick leave etc..)
                            </Typography>
                            <Typography className="mt-18 mb-18 padding-content">
                              <span className="font-bold">Consider this:</span>{" "}
                              reducing your contributions to as little as £2 per
                              month might be a more flexible option. This way,
                              you stay in the scheme and avoid needing to
                              reapply if you decide to increase your
                              contributions later.
                            </Typography>

                            <LoadingButton
                              buttonTitle={"Amend Plan"}
                              trackingDetails={
                                TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                              }
                              handleClick={() =>
                                navigate("/amend_my_shared_cost_avc")
                              }
                              styleClass={`cancellation-next-btn disable-text-transform thinbtn`}
                            />
                            <Typography className="mt-18 mb-18 padding-content">
                              By cancelling my Shared Cost AVC plan, I
                              understand I will have to go through the new
                              application process again if I change my mind in
                              the future.
                            </Typography>

                            <LoadingButton
                              buttonTitle={"Cancel Plan"}
                              trackingDetails={
                                TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                              }
                              handleClick={handleSubmit}
                              styleClass={`cancellation-next-btn cancel-plan disable-text-transform thinbtn`}
                              loading={cancelMyPlanLoading}
                            />
                          </Box>
                        )}
                        {values.cancellationReason ===
                          CANCELLATION_REASONS.leaving_or_left_employment && (
                          <Box
                            textAlign={"center"}
                            width={"82%"}
                            marginX={"auto"}
                          >
                            <img src={warningIcon} alt="warning" />
                            <Typography className="mt-18 new-padding-content-heading">
                              Leaving or Left Employment
                            </Typography>
                            <Typography className="mt-18 mb-18 padding-content">
                              Will you be moving to another Local Government
                              Pension Scheme organisation?
                            </Typography>
                            <FormSelect
                              name="isMovingToAnotherOrganisation"
                              labelId="isMovingToAnotherOrganisation"
                              label="Please choose..."
                              styleClass="new-select-form"
                              width={100}
                              menuItems={otherOrganisationOptions}
                              trackingDetails={
                                TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                              }
                              handleCustomChange={(_, value, setFieldValue) => {
                                handleMovingToOtherOrganisation(
                                  null,
                                  value,
                                  setFieldValue
                                );
                              }}
                            />
                            {values.isMovingToAnotherOrganisation === "yes" && (
                              <>
                                <Typography className="mt-18 mb-18 padding-content">
                                  If you’d like to continue contributing to a
                                  Shared Cost AVC at your new employer, let us
                                  know the name of the organisation you’re
                                  joining. We can explore the possibility of
                                  setting up a Shared Cost AVC scheme there for
                                  you.
                                </Typography>
                                <TextInput
                                  id="otherOrganisationName"
                                  name="otherOrganisationName"
                                  label={"Organisation name"}
                                  trackingDetails={
                                    TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                                  }
                                  value={values.otherOrganisationName || ""}
                                />
                              </>
                            )}
                            <Stack
                              justifyContent="center"
                              direction="row"
                              className="mt-30"
                            >
                              <LoadingButton
                                buttonTitle="Confirm cancellation"
                                trackingDetails={
                                  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                                }
                                handleClick={handleSubmit}
                                styleClass="cancellation-next-btn"
                                disabled={!isValid}
                                loading={cancelMyPlanLoading}
                              />
                            </Stack>
                          </Box>
                        )}

                        {values.cancellationReason ===
                          CANCELLATION_REASONS.cannot_afford && (
                          <Box textAlign={"center"}>
                            <img src={warningIcon} alt="warning" />
                            <Typography className="mt-18 new-padding-content-heading">
                              Cannot afford to make Shared Cost AVCs anymore
                            </Typography>
                            <Typography className="mt-18 mb-18 padding-content">
                              <span className="font-bold">Consider this:</span>{" "}
                              reducing your contributions to as little as £2 per
                              month might be a more flexible option. This way,
                              you stay in the scheme and avoid needing to
                              reapply if you decide to increase your
                              contributions later.
                            </Typography>

                            <LoadingButton
                              buttonTitle={"Amend Plan"}
                              trackingDetails={
                                TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                              }
                              handleClick={() =>
                                navigate("/amend_my_shared_cost_avc")
                              }
                              styleClass={`cancellation-next-btn disable-text-transform thinbtn`}
                            />
                            <Typography className="mt-18 mb-18 padding-content">
                              By cancelling my Shared Cost AVC plan, I
                              understand I will have to go through the new
                              application process again if I change my mind in
                              the future.
                            </Typography>

                            <LoadingButton
                              buttonTitle={"Cancel Plan"}
                              trackingDetails={
                                TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                              }
                              handleClick={handleSubmit}
                              styleClass={`cancellation-next-btn cancel-plan disable-text-transform thinbtn`}
                              loading={cancelMyPlanLoading}
                            />
                          </Box>
                        )}

                        {values.cancellationReason ===
                          CANCELLATION_REASONS.dont_want_to && (
                          <Box textAlign={"center"}>
                            <img src={warningIcon} alt="warning" />
                            <Typography className="mt-18 new-padding-content-heading">
                              Don’t want to make Shared Cost AVCs anymore
                            </Typography>
                            <Typography className="mt-18 mb-18 padding-content">
                              <span className="font-bold">
                                Before you cancel, consider this:
                              </span>{" "}
                              By stopping your Shared Cost AVCs, you’ll miss out
                              on valuable, cost-efficient savings for your
                              retirement. Your contributions benefit from Income{" "}
                              <span className="font-bold">
                                Tax and National Insurance (NICs) relief
                              </span>
                            </Typography>

                            <Typography className="mt-18 mb-8 padding-content">
                              Would reducing your contributions be a better
                              option? You can contribute as little as £2 while
                              keeping your savings on track.
                            </Typography>
                            <Stack
                              justifyContent="center"
                              direction={{ md: "row", xs: "column" }}
                              alignItems={"center"}
                              className="mt-18 mb-18"
                              gap={2}
                            >
                              <LoadingButton
                                buttonTitle={"Amend Plan"}
                                trackingDetails={
                                  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                                }
                                handleClick={() =>
                                  navigate("/amend_my_shared_cost_avc")
                                }
                                styleClass={`cancellation-next-btn disable-text-transform thinbtn`}
                              />
                              <LoadingButton
                                buttonTitle={"Speak to us 1:1"}
                                trackingDetails={
                                  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                                }
                                styleClass={`cancellation-next-btn cancel-plan disable-text-transform thinbtn`}
                              />
                            </Stack>
                            <Typography className="mt-18 mb-18 padding-content">
                              By cancelling my Shared Cost AVC plan, I
                              understand I will have to go through the new
                              application process again if I change my mind in
                              the future.
                            </Typography>

                            <LoadingButton
                              buttonTitle={"Cancel Plan"}
                              trackingDetails={
                                TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                              }
                              handleClick={handleSubmit}
                              styleClass={`cancellation-next-btn cancel-plan disable-text-transform thinbtn`}
                              loading={cancelMyPlanLoading}
                            />
                          </Box>
                        )}

                        {values.cancellationReason ===
                          CANCELLATION_REASONS.other_reason && (
                          <Box
                            textAlign={"center"}
                            width={"82%"}
                            marginX={"auto"}
                          >
                            <img src={warningIcon} alt="warning" />
                            <Typography className="mt-18 new-padding-content-heading">
                              Other
                            </Typography>
                            <Typography className="mt-18 mb-18 padding-content">
                              We're sorry to see you go, but we hope to assist
                              you again in the future. You’re welcome to reapply
                              and join the scheme anytime.
                            </Typography>

                            <TextInput
                              id="otherReason"
                              name="otherReason"
                              label="Other Reason"
                              styleClass={"full-width"}
                              trackingDetails={
                                TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                              }
                              value={values.otherReason || ""}
                            />

                            <Stack
                              justifyContent="center"
                              direction="row"
                              className="mt-30"
                            >
                              <LoadingButton
                                buttonTitle="Confirm cancellation"
                                trackingDetails={
                                  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                                }
                                handleClick={handleSubmit}
                                styleClass="cancellation-next-btn"
                                disabled={!isValid}
                                loading={cancelMyPlanLoading}
                              />
                            </Stack>
                          </Box>
                        )}
                      </>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      )}
      {cancelMyPlanLoading && (
        <LoadingOverlay title="Please wait while we cancel your plan..." />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.user?.user,
  };
};

NewCancellationReasons.propTypes = {
  setCancel: PropTypes.array,
  plan: PropTypes.object,
  step: PropTypes.number,
  setStep: PropTypes.func,
  handleClose: PropTypes.func,
  user: PropTypes.object,
};

export default connect(mapStateToProps)(NewCancellationReasons);
