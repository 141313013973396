import React, { useEffect, useState } from "react";
import { Box, Container, Modal } from "@mui/material";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import usePlan from "../../../../../../hooks/usePlan";
import usePlanDetails from "../../../../../../hooks/usePlanDetails";
import IconButton from "../../../../../generic-components/icon-btn";
import CloseIcon from "@mui/icons-material/Close";
import { TRACKING_NAF_OPTIONS } from "../../../../../../constants";
import NewCancellationReasons from "./NewCancellationReasons";
import LottieLoader from "../../../../../generic-components/lotte-loader";

const CancellationModal = ({ open, handleClose, user, selectedPlan }) => {
  const { plans } = usePlan();
  const { fetchCompletePlanDetails, isPlanLoading } = usePlanDetails();
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (user.id && user.organisation_id) {
      fetchCompletePlanDetails(user.id, user.organisation_id);
    }
  }, [user.organisation_id, user.id]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="cancellation-modal-background modal-background"
    >
      <Container>
        {isPlanLoading ? (
          <Box className="mt-20">
            <LottieLoader />
          </Box>
        ) : (
          <Box className="cancellation-modal">
            <IconButton
              trackingDetails={TRACKING_NAF_OPTIONS}
              extraStyles={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                cursor: "pointer",
                zIndex: 2,
              }}
              handleClick={handleClose}
              styleClass="call"
              icon={<CloseIcon />}
            />
            <Box
              className="pagination-container"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                mb: 2,
              }}
            >
              <Box sx={{ display: "flex", gap: "5px" }}>
                <Box
                  className="pagination-circle"
                  onClick={() => setStep(1)}
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    backgroundColor: step === 1 ? "#C06DF3" : "",
                    border: "1px solid #C06DF3",
                  }}
                />
                <Box
                  className="pagination-circle"
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    backgroundColor: step === 2 ? "#C06DF3" : "",
                    border: "1px solid #C06DF3",
                  }}
                />
              </Box>
            </Box>
            <NewCancellationReasons
              plan={plans.length > 1 ? selectedPlan : plans[0]}
              setStep={setStep}
              step={step}
              handleClose={handleClose}
            />
          </Box>
        )}
      </Container>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.user?.user,
  };
};

CancellationModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedPlan: PropTypes.object,
  user: PropTypes.object,
};

export default connect(mapStateToProps)(CancellationModal);
