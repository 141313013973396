import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import emailIcon from "./../../../../../assets/images/email-icon.png";
import phoneIcon from "./../../../../../assets/images/phone-icon.png";

const NewCustomerServiceFooter = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const liveChatTrigger = () => {
    window?.zE && window?.zE("messenger", "show");
    window?.zE && window?.zE("messenger", "open");
    window.location.href = "tel:01252959779";
  };

  const handleOpenEmailClient = () => {
    const email = process.env.REACT_APP_SUPPORT_EMAIL;
    const mailtoLink = `mailto:${email}`;
    window.open(mailtoLink);
  };

  return (
    <Box className="new-customer-service-container mt-50 mb-30">
      <Typography className="customer-service-heading">
        Need any help?
      </Typography>
      <Typography variant="h5" className="customer-service-content mt-5 mb-5">
        We're here for you, contact us on:
      </Typography>
      <Stack
        direction={{ lg: "row", md: "row", sm: "column", xs: "row" }}
        width={"100%"}
        spacing={3}
        className="mt-18"
      >
        <Box
          className="customer-service-button service-email"
          display={"flex"}
          direction={"row"}
          alignItems={"center"}
          flex={1}
          gap={1}
          padding={2}
          onClick={handleOpenEmailClient}
        >
          <img src={emailIcon} alt="emai-icon" className="contact-icon" />

          <Stack direction={"column"} alignItems={"start"}>
            <Typography className="button-heading">Email</Typography>
            {!isMobile && (
              <Typography className="value">
                {process.env.REACT_APP_SUPPORT_EMAIL}
              </Typography>
            )}
          </Stack>
        </Box>

        <Box
          className="customer-service-button service-phone"
          display={"flex"}
          direction={"row"}
          alignItems={"center"}
          gap={1}
          flex={1}
          padding={2}
          onClick={liveChatTrigger}
        >
          <img className="contact-icon" src={phoneIcon} alt="phone-icon" />

          <Stack direction={"column"} alignItems={"start"}>
            <Typography className="button-heading">Phone</Typography>
            {!isMobile && (
              <Typography className="value">
                {`01252 959 779 (8:30am-5:30pm)`}
              </Typography>
            )}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default NewCustomerServiceFooter;
