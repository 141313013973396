import React, { useState } from "react";
import { Box, Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  providers,
  statusColor,
  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS,
} from "../../../../../constants";
import LoadingButton from "../../../../generic-components/button";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import useStepper from "../../../../../hooks/useStepper";
import { downloadApplicationForm } from "../../../../../graphql/queries/manageMySharedCost";
import { formatCurrency } from "../../../../../helpers";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import waitingIcon from "../../../../../assets/images/waiting-icon.png";
import sucessIcon from "../../../../../assets/images/success-icon.png";
import errorIcon from "../../../../../assets/images/error-icon.png";
import CancellationModal from "../cancel-my-shared-cost-avc/cancel-my-shared-cost-plan/CancellationModal";
import usePlan from "../../../../../hooks/usePlan";

const StatusIcon = ({ status }) => {
  if (status === "cancelled") {
    return <img src={errorIcon} />;
  }
  if (status === "accepted") {
    return <img src={sucessIcon} />;
  }
  if (status === "awaiting authorisation") {
    return <img src={waitingIcon} />;
  }
  return <></>;
};

StatusIcon.propTypes = {
  status: PropTypes.string,
};

const PlanStatus = ({ planDetails, amendedPlan }) => (
  <Stack direction={"column"} gap={2}>
    <Stack gap={0.5} direction="column">
      <Typography className="status-heading">Plan Status</Typography>
      <Typography
        className="status-name"
        color={(theme) =>
          theme.palette.mmm[
            statusColor[planDetails?.plan_status?.name.toLowerCase()]
          ]
        }
        display="flex"
        alignItems="center"
        gap={1}
      >
        <StatusIcon status={planDetails?.plan_status?.name.toLowerCase()} />
        {planDetails?.plan_status?.name || "Loading..."}
      </Typography>
    </Stack>

    {planDetails?.plan_status?.name === "Awaiting Authorisation" && (
      <>
        <Typography
          variant="body2"
          className="font-semibold"
          fontSize={{ md: "1.625rem", xs: "1.125rem" }}
          lineHeight={{ md: "100%", xs: "100%" }}
        >
          {`You’ve ${
            amendedPlan ? "amended your plan" : "applied"
          } to make contributions`}
          .
        </Typography>
        <Stack gap={1} direction="column" alignItems="start">
          <Typography
            variant="body1"
            className="font-medium"
            fontSize={{ md: "1.375rem", xs: "1.063rem" }}
            lineHeight={{ md: "1.95rem", xs: "1.875rem" }}
          >
            What happens next.
          </Typography>
          <ol className="ml-30 font-normal awaiting-list">
            <li>
              <Typography>
                Staffordshire County Council will check the details of your
                application
              </Typography>
            </li>
            <li>
              <Typography>
                You’ll get a new contract to put this in place
              </Typography>
            </li>
            <li>
              <Typography>
                We’ll send you an email to let you know that your pension
                contributions are starting
              </Typography>
            </li>
          </ol>
        </Stack>
      </>
    )}
  </Stack>
);

PlanStatus.propTypes = {
  planDetails: PropTypes.object,
  amendedPlan: PropTypes.bool,
};

const PlanInfo = ({ planDetails, amendedPlan, planLength }) => {
  const { setSaveError, setErrorToast } = useStepper();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [downloadForm, { loading: downloadLoading }] = useLazyQuery(
    downloadApplicationForm
  );
  const [cancelledPlanModal, setCancelledPlanModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { setForcefullyFetchPlans, forcefullyFetchPlans } = usePlan();

  const handleDownloadForm = async (planId) => {
    downloadForm({
      variables: {
        plan_id: planId,
      },
      onCompleted: (data) => {
        const applicationFormLink = data?.plan_details?.plans?.application_form;
        window.open(applicationFormLink, "_blank");
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const handleCloseCancellationModal = async (val) => {
    if (val) {
      setForcefullyFetchPlans(!forcefullyFetchPlans);
    }
    setCancelledPlanModal(false);
    setSelectedPlan(null);
  };

  const handlePensionPot = (provider) => {
    const providerUrls = {
      [providers.LEGAL_AND_GENERAL]: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/samlp/${process.env.REACT_APP_LNG_CLIENT_ID}`,
      [providers.PRUDENTIAL]:
        "https://www.mandg.com/pru/workplace-pensions/employees/public-sector-avc-schemes/local-gov",
      [providers.STANDARD_LIFE]:
        "https://www.standardlifepensions.com/gavc/index",
      [providers.CLERICAL_MEDICAL]: "https://www.clericalmedical.co.uk/",
    };

    const url = providerUrls[provider];
    if (url) window.open(url, "_blank");
  };

  const isPlanAcceptedOrSingle = ![
    "Cancelled",
    "Opted-out",
    "Opt Out Pending",
  ].includes(planDetails?.plan_status?.name);

  return (
    <Stack
      className="info-container"
      direction={"column"}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: isMobile ? "0rem" : "1.5rem",
      }}
      gap={isMobile ? 1 : 2}
    >
      <Stack>
        <Typography className="status-heading">Plan Summary</Typography>
      </Stack>
      <Stack direction={{ md: "row", sm: "column" }} gap={2}>
        <Stack
          gap={0.5}
          direction="column"
          className="plan-summary"
          width={{ xs: "100%", md: "50%" }}
          flex={2.5}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            gap={2}
            width={"100%"}
            flex={1}
            marginTop={"1rem"}
            padding={"1rem"}
          >
            <Stack direction="column" alignItems="center" flex={1}>
              <Typography className="plan" sx={{ textAlign: "center" }}>
                Start date
              </Typography>
              <Typography className="plan">
                <span className="value">
                  {moment(planDetails?.created_at).format("D MMM YY")}
                </span>
              </Typography>
            </Stack>
            {!isMobile && (
              <Divider orientation="vertical" variant="middle" flexItem />
            )}
            <Stack
              direction="row"
              justifyContent={{ xs: "space-between", md: "flex-start" }}
              gap={2}
              flex={2}
            >
              <Stack direction="column" alignItems="center" flex={1}>
                <Typography className="plan" sx={{ textAlign: "center" }}>
                  Payroll frequency
                </Typography>
                <Typography className="plan">
                  <span className="value">
                    {planDetails?.avc_interval || ""}
                  </span>
                </Typography>
              </Stack>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Stack direction="column" alignItems="center" flex={1}>
                <Typography className="plan" sx={{ textAlign: "center" }}>
                  Contribution per payroll
                </Typography>
                <Typography className="plan">
                  <span className="value">
                    {formatCurrency(planDetails?.total_avc_amount_requested)}
                  </span>
                </Typography>
                {!amendedPlan &&
                planLength < 2 &&
                planDetails?.plan_status?.name === "Accepted" ? (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    className="amend-button"
                  >
                    <LoadingButton
                      trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                      icon={<CreateOutlinedIcon className="med-purple-text" />}
                      disabled={downloadLoading}
                      handleClick={() =>
                        navigate("/amend_my_shared_cost_avc", {
                          state: { backUrl: "/manage_my_shared_cost_avc" },
                        })
                      }
                      styleClass="med-purple-text"
                    />
                    <Typography className="underline-text med-purple-text font-semibold">
                      Amend
                    </Typography>
                  </Stack>
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>
          </Stack>

          <Box
            className="plan-actions"
            padding={{ xs: "0.5rem", md: "1rem" }}
            display={"flex"}
            alignItems={"center"}
            justifyContent={!amendedPlan ? "space-between" : "end"}
          >
            {!amendedPlan ? (
              <Stack
                className="downlaod-application"
                gap={1}
                onClick={() => handleDownloadForm(planDetails?.id)}
                sx={{ cursor: "pointer" }}
              >
                <LoadingButton
                  trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                  disabled={downloadLoading}
                  loading={downloadLoading}
                  onClick={() => handleDownloadForm(planDetails?.id)}
                  styleClass="med-purple-text bg-purple-text-white"
                  icon={<FileDownloadOutlinedIcon />}
                />
                <Typography className="align-self-center underline-text font-semibold">
                  Download my completed application form
                </Typography>
              </Stack>
            ) : (
              <></>
            )}

            {isPlanAcceptedOrSingle ? (
              <Stack
                className={"cancle-button"}
                justifyContent="center"
                alignItems="center"
                direction={"row"}
              >
                <LoadingButton
                  buttonTitle="Cancel"
                  icon={<DoNotDisturbIcon fontSize="small" className="mr-5" />}
                  trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                  handleClick={() => {
                    setCancelledPlanModal(true);
                    setSelectedPlan(planDetails);
                  }}
                  styleClass="fw-400 px-0 text-center pi-0 fs-14 font-bold underline-text capitalize-text med-purple-text bg-purple-text-white"
                  disabled={downloadLoading}
                />
              </Stack>
            ) : null}
          </Box>
        </Stack>
        <Stack flex={1.5}>
          {planDetails?.plan_status?.name === "Accepted" &&
            Object.values(providers).includes(
              planDetails?.provider?.name?.toLowerCase()
            ) && (
              <>
                <Typography
                  variant="h1"
                  className="purple-text font-semibold"
                  fontSize={{ md: "2.25rem", xs: "2rem" }}
                  lineHeight={{ xs: "2.5rem" }}
                >
                  Your pension pot
                </Typography>
                <Typography
                  className="mt-10 font-normal"
                  fontSize={{ md: "1.375rem", xs: "1.25rem" }}
                  width={{ md: "90%", xs: "100%" }}
                  marginTop={{ md: "12px" }}
                  sx={{
                    width: "334px !important",
                    fontWeight: "400px !important",
                    textAlign: "justify",
                  }}
                >
                  See the total value in your pension pot and change where your
                  money is invested
                </Typography>
                <LoadingButton
                  buttonTitle={`Manage on ${planDetails?.provider?.name}`}
                  trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                  handleClick={() =>
                    handlePensionPot(planDetails?.provider?.name?.toLowerCase())
                  }
                  styleClass={"pension-place-btn mt-18"}
                  icon={<OpenInNewIcon className="ml-5" />}
                />
              </>
            )}
        </Stack>
        {cancelledPlanModal && (
          <CancellationModal
            open={cancelledPlanModal}
            handleClose={handleCloseCancellationModal}
            selectedPlan={selectedPlan}
          />
        )}
      </Stack>
    </Stack>
  );
};

PlanInfo.propTypes = {
  planDetails: PropTypes.object,
  amendedPlan: PropTypes.bool,
  planLength: PropTypes.number,
  user: PropTypes.object,
};

const NewPlanDetails = ({ planDetails, amendedPlan, planLength, user }) => {
  return (
    <>
      <Box className="banner-container">
        <Stack direction="column">
          <PlanStatus planDetails={planDetails} amendedPlan={amendedPlan} />
        </Stack>
      </Box>
      <PlanInfo
        planDetails={planDetails}
        amendedPlan={amendedPlan}
        planLength={planLength}
        user={user}
      />
    </>
  );
};

NewPlanDetails.propTypes = {
  organisation: PropTypes.object,
  planDetails: PropTypes.object,
  planStatusBg: PropTypes.string,
  amendedPlan: PropTypes.bool,
  planLength: PropTypes.number,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  organisation: state?.organisation?.organisation,
  user: state?.user?.user,
});

export default connect(mapStateToProps)(NewPlanDetails);
