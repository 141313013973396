import React, { useEffect, useState } from "react";
import { Box, Container, Divider } from "@mui/material";
import PlanDetailsComponent from "./NewPlanDetails";
import PlanGuidanceComponent from "./NewPlanGuidance";
import planStatusBg from "../../../../../assets/images/bg.png";
import PropTypes from "prop-types";
import NewCustomerServiceFooter from "./NewCustomerServiceFooter";
import usePlan from "../../../../../hooks/usePlan";
import StaffBenefitsWidget from "../../staff-benefits-page/StaffBenefitsWidget";
import { TRACKING_MANAGE_MY_SHARED_COST_OPTIONS } from "../../../../../constants";
import { connect } from "react-redux";
import useStepper from "../../../../../hooks/useStepper";
import { getSchemeDetails } from "../../../../../graphql/queries/manageMySharedCost";
import { useLazyQuery } from "@apollo/client";
import NewTermsAndFaqs from "./NewTermsAndFaqs";
import ContributeShape from "../../../../../assets/images/contribute_shape.png";

const NewManageMySharedCostWithPlans = ({ plans, scheme }) => {
  const { isLatestActivePlan } = usePlan();
  const { setErrorToast, setSaveError } = useStepper();
  const [schemeDocs, setSchemeDocs] = useState(null);
  const [fetchSchemeDocs] = useLazyQuery(getSchemeDetails);

  const getSchemeDocs = async () => {
    fetchSchemeDocs({
      fetchPolicy: "no-cache",
      refetchWritePolicy: "overwrite",
      variables: {
        scheme_id: scheme.scheme_id,
      },
      onCompleted: (data) => {
        setSchemeDocs(data?.scheme_details);
      },
      onError: (error) => {
        setErrorToast(true);
        setSaveError(error);
      },
    });
  };

  useEffect(() => {
    if (scheme?.scheme_id) getSchemeDocs();
  }, [scheme]);

  return (
    <div className="new-plan-main-container">
      {plans?.length > 1 ? (
        <Container className="" maxWidth={false}>
          <Box className="new-plan-detail-container mb-30">
            <PlanDetailsComponent
              planDetails={
                plans.find((plan) => plan?.plan_status?.name !== "Accepted") ||
                plans[1]
              }
              planStatusBg={planStatusBg}
              amendedPlan={true}
              planLength={plans?.length}
            />
          </Box>
          {/* <div className="plan-status-divider" /> */}
          <Divider sx={{ position: "relative" }} />
        </Container>
      ) : (
        <></>
      )}
      <Container className="" maxWidth={false}>
        <Box
          className={`new-plan-detail-container ${
            plans?.length <= 1 ? "" : ""
          }`}
        >
          <PlanDetailsComponent
            planDetails={
              plans?.length > 1
                ? plans.find((plan) => plan?.plan_status?.name === "Accepted")
                : plans[0]
            }
            planStatusBg={planStatusBg}
            amendedPlan={false}
            planLength={plans?.length}
          />
        </Box>
        <Box className="new-plan-detail-container mt-12">
          <NewTermsAndFaqs
            faq={schemeDocs?.faq}
            termsAndConditions={schemeDocs?.terms_and_conditions}
          />
        </Box>
      </Container>

      <Box className="new-plan-guidance-container mt-50">
        <PlanGuidanceComponent planDetails={plans[0]} />
      </Box>
      <Box className="new-plan-guidance-image-container">
        <img
          src={ContributeShape}
          alt="contribute shape"
          className="contribute-shape-image"
        />
      </Box>
      <Container className="" maxWidth={false}>
        <NewCustomerServiceFooter />
        {isLatestActivePlan ? (
          <StaffBenefitsWidget
            trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
          />
        ) : null}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    scheme: state.scheme.scheme,
  };
};

NewManageMySharedCostWithPlans.propTypes = {
  scheme: PropTypes.object,
  plans: PropTypes.array,
};

export default connect(mapStateToProps)(NewManageMySharedCostWithPlans);
