import React from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import mmmLogo from "../../../assets/images/mmm-white-logo.png";
import {
  footerLinks,
  footerSocialMediaLinks,
  TRACKING_FOOTER_OPTIONS,
} from "../../../constants";
import NavigationLinks from "../../generic-components/navigation-links";
import SocialMediaLinks from "../../generic-components/socialmedia-links";
import LogoButton from "../../generic-components/logo-btn";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Footer = ({ organisation }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:900px)");
  return (
    <>
      <Box className="footer">
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Box className="footer-content">
                <Stack
                  direction={{ md: "row", sm: "column" }}
                  justifyContent="space-between"
                  className="mb-30"
                >
                  <Box className="footer-logo-container">
                    <LogoButton
                      name="MMM"
                      logoPath={mmmLogo}
                      trackingDetails={TRACKING_FOOTER_OPTIONS}
                      styleClass="footer-logo"
                    />
                  </Box>
                  <Stack
                    direction="column"
                    alignItems={{ md: "end", sm: "start" }}
                  >
                    <Typography
                      variant={isMobile ? "h6" : "h4"}
                      color="white"
                      fontWeight="600"
                      className={isTablet ? "mt-30" : ""}
                    >
                      Your platform{" "}
                      <span className="lighter-purple-v2">for prosperity</span>
                    </Typography>
                    <Stack
                      direction={{
                        md: "row",
                        sm: "column",
                        xs: "column",
                      }}
                      spacing={{
                        md: 2,
                        sm: 1,
                        xs: 1,
                      }}
                      flexWrap="wrap"
                      alignItems="start"
                      justifyContent={{ md: "flex-end", sm: "flex-start" }}
                      className={isTablet ? "mt-30" : "mt-10"}
                    >
                      {footerLinks.map((val, index) => (
                        <>
                          {val.name === "Privacy Policy" &&
                          organisation?.privacy_policy_pdf ? (
                            <NavigationLinks
                              styleClass="footerlinks nav-links"
                              link={organisation?.privacy_policy_pdf}
                              name={val.name}
                              trackingDetails={TRACKING_FOOTER_OPTIONS}
                              newTab={true}
                              key={index}
                            />
                          ) : (
                            <NavigationLinks
                              styleClass="footerlinks nav-links"
                              link={val.link}
                              name={val.name}
                              trackingDetails={TRACKING_FOOTER_OPTIONS}
                              newTab={true}
                              key={index}
                            />
                          )}
                        </>
                      ))}
                    </Stack>
                  </Stack>
                </Stack>
                <Divider style={{ background: "#3E394D" }} />
                <Stack
                  className={isTablet ? "mt-30" : "mt-18"}
                  direction={{
                    md: "row-reverse",
                    sm: "column",
                  }}
                  justifyContent="space-between"
                  spacing={{ md: 0, sm: 2, xs: 2 }}
                  alignItems={{ md: "center", sm: "left" }}
                >
                  <Stack
                    className="socialmedia"
                    direction="row"
                    spacing={2}
                    mt={2}
                  >
                    {footerSocialMediaLinks.map((val, index) => (
                      <SocialMediaLinks
                        link={val.link}
                        name={val.name}
                        iconPath={val.iconPath}
                        trackingDetails={TRACKING_FOOTER_OPTIONS}
                        key={index}
                      />
                    ))}
                  </Stack>
                  <Typography
                    color="white"
                    variant={isTablet ? "body2" : "body1"}
                    className={isTablet ? "mt-30" : ""}
                  >
                    © {new Date().getFullYear()}, AVC Wise Ltd (t/a My Money
                    Matters). All Rights reserved. Company Number 11406613
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    organisation: state?.organisation?.organisation,
  };
};

Footer.propTypes = {
  organisation: PropTypes.object,
};

export default connect(mapStateToProps)(Footer);
